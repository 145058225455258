function checkInput() {
    // check input value
    // $("body").on("change", "input, textarea", function () {
    $("body").on("change", "input", function () {

        if ($(this).val().length > 0) {
            $(this).removeClass("error");
        }

        if ($(this).attr("type") == "checkbox") {
            const inputname = $(this).attr("name");
            $("input[name=" + inputname + "]").removeClass("error");
        }
    });
}
export { checkInput };

function callFilledClass() {
    var i;
    // var inputField = document.getElementsByTagName("input");
    var inputField = document.querySelectorAll("input, select, textarea");

    for (i = 0; i < inputField.length; i++) {
        inputField[i].onblur = function () {
            // console.log("blur");
    
            if (this.value.length > 0) {
            this.classList.add("filled");
            this.classList.remove("invalid");
            } else if (this.value.length == 0) {
            this.classList.remove("filled");
            }
        };
    }
}

export { callFilledClass };


function buttonchange() {
    const actualBtn = document.getElementById('additional_files');
    if (actualBtn!=undefined) {
        const fileChosen = document.getElementById('file-chosen');
        const newButton = document.getElementById('button_additional_files');
        //Fotos hochladen
        newButton.addEventListener("click", function(){
            fileChosen.textContent="Fotos hochladen";
        });
        actualBtn.addEventListener('change', function(){
            console.log(fileChosen.textContent);
            if (this.files.length>1) {
                fileChosen.textContent = this.files.length + " Dateien";
            }
            else
            {
                fileChosen.textContent = this.files[0].name;
            }
        });
    }

}

export { buttonchange };