import "owl.carousel";
function heroslider() {
  $(".hero-slider").owlCarousel({
    nav: true,
    navText: [
      '<svg id="Komponente_4_1" data-name="Komponente 4 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow"><g id="Gruppe_25" data-name="Gruppe 25"><path id="Pfad_44" data-name="Pfad 44" d="M.275,137.149h0l4.9-4.875A.938.938,0,0,1,6.5,133.6l-3.289,3.273H23.063a.937.937,0,1,1,0,1.875H3.208L6.5,142.023a.938.938,0,0,1-1.323,1.329l-4.9-4.875h0A.938.938,0,0,1,.275,137.149Z" transform="translate(0 -132)"/></g></g></svg>',
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow" transform="translate(0 -132)"><g id="Gruppe_25" data-name="Gruppe 25" transform="translate(0 132)"><path id="Pfad_44" data-name="Pfad 44" d="M23.725,137.149h0l-4.9-4.875A.938.938,0,0,0,17.5,133.6l3.289,3.273H.937a.937.937,0,1,0,0,1.875H20.792L17.5,142.023a.938.938,0,0,0,1.323,1.329l4.9-4.875h0A.938.938,0,0,0,23.725,137.149Z" transform="translate(0 -132)"/></g></g></svg>',
    ],
    loop: false,
    rewind: true,
    autoplay: false,
    singleItem:true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  });
  $(".owl-dot").attr('aria-label',"Bilderwechsel");
  $(".owl-prev").attr('aria-label',"Zurück").attr('role',"button");
  $(".owl-next").attr('aria-label',"Weiter").attr('role',"button");
}

export { heroslider };
