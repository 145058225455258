import "owl.carousel";
function klickpoint() {
  let owlSliderSimple = jQuery(".wohntrend-klickpoint-slider");
  if (owlSliderSimple.length!=0) {
    owlSliderSimple.owlCarousel({
      nav: true,
      navText: [
        '<span class="owl-carousel-arrow"><svg id="Komponente_4_1" data-name="Komponente 4 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow"><g id="Gruppe_25" data-name="Gruppe 25"><path id="Pfad_44" data-name="Pfad 44" d="M.275,137.149h0l4.9-4.875A.938.938,0,0,1,6.5,133.6l-3.289,3.273H23.063a.937.937,0,1,1,0,1.875H3.208L6.5,142.023a.938.938,0,0,1-1.323,1.329l-4.9-4.875h0A.938.938,0,0,1,.275,137.149Z" transform="translate(0 -132)"/></g></g></svg></span>',
        '<span class="owl-carousel-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow" transform="translate(0 -132)"><g id="Gruppe_25" data-name="Gruppe 25" transform="translate(0 132)"><path id="Pfad_44" data-name="Pfad 44" d="M23.725,137.149h0l-4.9-4.875A.938.938,0,0,0,17.5,133.6l3.289,3.273H.937a.937.937,0,1,0,0,1.875H20.792L17.5,142.023a.938.938,0,0,0,1.323,1.329l4.9-4.875h0A.938.938,0,0,0,23.725,137.149Z" transform="translate(0 -132)"/></g></g></svg></span>',
      ],
      loop: false,
      rewind: true,
      autoplay: false,
      lazyLoad: true,
      dots: true,
      stagePadding: 400,
      startPosition: 1,
      responsive: {
        0: {
          items: 1,
          stagePadding: 0,
        },
        768: {
          items: 1,
          stagePadding: 0,
        },
        1200: {
          items: 1,
          stagePadding: 200,
        },
        1280: {
          items: 1,
          stagePadding: 250,
        },
        1370: {
          items: 1,
          stagePadding: 300,
        },
        1460: {
          items: 1,
          stagePadding: 350,
        },
        1570: {
          items: 1,
          stagePadding: 400,
        },
        1685: {
          items: 1,
          stagePadding: 300,
        },
        1840: {
          items: 1,
          stagePadding: 400,
        },
      },

      onInitialized: function(event) {
        let element = jQuery(event.target);
        let idx = event.item.index;
        //console.log(idx);

        element.find('.owl-item.left').removeClass('left');

        element.find('.owl-item.second').removeClass('second');
        element.find('.owl-item').eq(idx + 1).addClass('second');
        element.find('.owl-item').eq(idx - 1).addClass('left');
        //element.find('.owl-item').eq(idx - 1).find("img").addClass("lazypreload");
      },
    });

    var rect = jQuery(".owl-carousel")[0].getBoundingClientRect();
    //console.log(rect);
    // owlSliderSimple.on('initialized.owl.carousel translate.owl.carousel resize.owl.carousel', function(e){
    owlSliderSimple.on('translate.owl.carousel resized.owl.carousel', function(e){
      let element = jQuery(e.target);
      let idx = e.item.index;

        // console.log(rect);
        // console.log($(window).height());
        // if (
        //     rect.top >= 0 &&
        //     rect.left >= 0 &&
        //     // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        //     // rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        //     rect.bottom <= $(window).height() && /* or $(window).height() */
        //     rect.right <= $(window).width() /* or $(window).width() */
        // )
        // {
        //   console.log(idx);
        //   idx=6;
        //   e.item.index=6;
        // };

        element.find('.owl-item.left').removeClass('left');

        element.find('.owl-item.second').removeClass('second');
        element.find('.owl-item').eq(idx + 1).addClass('second');
        element.find('.owl-item').eq(idx - 1).addClass('left');

        // console.log(idx);
    });
  }
  $(".owl-dot").attr('aria-label',"slide-dot");
  $(".owl-prev").attr('aria-label',"slide-back");
  $(".owl-next").attr('aria-label',"slide-next");
}

export { klickpoint };



function coordinates(x_pos, y_pos) {
    var d = document.getElementById('clicktrigger');
    d.style.position = "absolute";
    d.style.left = x_pos+'px';
    d.style.top = y_pos+'px';
}


export {coordinates};