$(window).scroll(function () {
    inViewport();
  });
  
  $(window).resize(function () {
    inViewport();
  });
  
  function inViewport() {
    $(".animation-element").each(function () {
      var divPos = $(this).offset().top,
        topOfWindow = $(window).scrollTop();
  
      if (divPos < topOfWindow + 800) {
        $(this).addClass("in-view");
      } else {
        //$(this).removeClass("in-view");
        // nothing
      }
    });
  }
  
  export { inViewport };