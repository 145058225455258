import "owl.carousel";
function prodctslider() {
  let prodctslider = jQuery(".product-slider");

  prodctslider.owlCarousel({
    nav: true,
    navText: [
      '<span class="owl-carousel-arrow"><svg id="Komponente_4_1" data-name="Komponente 4 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow"><g id="Gruppe_25" data-name="Gruppe 25"><path id="Pfad_44" data-name="Pfad 44" d="M.275,137.149h0l4.9-4.875A.938.938,0,0,1,6.5,133.6l-3.289,3.273H23.063a.937.937,0,1,1,0,1.875H3.208L6.5,142.023a.938.938,0,0,1-1.323,1.329l-4.9-4.875h0A.938.938,0,0,1,.275,137.149Z" transform="translate(0 -132)"/></g></g></svg></span>',
      '<span class="owl-carousel-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow" transform="translate(0 -132)"><g id="Gruppe_25" data-name="Gruppe 25" transform="translate(0 132)"><path id="Pfad_44" data-name="Pfad 44" d="M23.725,137.149h0l-4.9-4.875A.938.938,0,0,0,17.5,133.6l3.289,3.273H.937a.937.937,0,1,0,0,1.875H20.792L17.5,142.023a.938.938,0,0,0,1.323,1.329l4.9-4.875h0A.938.938,0,0,0,23.725,137.149Z" transform="translate(0 -132)"/></g></g></svg></span>',
    ],
    mouseDrag: false,
    loop: false,
    dots: true,
    margin: 0,
    stagePadding: 0,
    autoplay: false,
    smartSpeed: 750,
    stagePadding: 0,
    items: 4,
    lazyLoad: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
      1640: {
        items: 4,
      },
    },
  });
  $(".owl-dot").attr('aria-label',"Dot");
  $(".owl-prev").attr('aria-label',"Previous").attr('role',"button");
  $(".owl-next").attr('aria-label',"Next").attr('role',"button");
}

export { prodctslider };