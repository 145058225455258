import "@fancyapps/fancybox";

function video_fancbox() {
    $('.video-wrap [data-fancybox^="video"]').fancybox({
        // 'width': fit-content,
        // 'height': fit-content,
        'autoSize': false,
        baseClass: 'customVid',
    });

}

export { video_fancbox };