import jQueryBridget from "jquery-bridget";
import Isotope from "isotope-layout";
jQueryBridget("isotope", Isotope, $);

function employeeIsotopeSorter() {
  let $grid = $(".employee-row").isotope({});

  var filters = {};
  $('.filters').on( 'click', '.employee-filter-button', function( ) {
    // var $button = $( event.currentTarget );
    // get group key
    var $buttonGroup = $(this).parents('.employee-buttons-row');
    var filterGroup = $buttonGroup.attr('data-filter-group');
    // set filter for group
    filters[ filterGroup ] = $(this).attr('data-filter');
    // combine filters
    //var filterValue = concatValues( filters );
    // set filter for Isotope
    console.log(filters);
    $grid.isotope({ filter: function(itemElem1, itemElem2 ) {

      let itemElem=0;
      //isotope nutzt 2 parameter. itemElem den richtigen parameter zuweisen
      if (itemElem1!=0) {
        itemElem=itemElem1;
      }
      else if(itemElem2!=0)
      {
        itemElem=itemElem2;
      }
      //maximal erlaubte Anzahl an Autos auf 6 zurücksetzen
      let filterValid=true;
      for(var prop in filters)
      {
        //Prüfen, ob Filter auf Alle steht
        if (filters[prop]!="" && filters[prop]!="*") {
          //Prüfen, ob element Filterelement enthält
          if (itemElem.classList.contains(filters[prop]) && filterValid)
          {
              filterValid=true;
          }
          else
          {
            filterValid=false;
          }
        }
      }
      if(filterValid)
          {
            return true;
          }
          else
          {
            return false;
          }
    }

    });
  });

  // change is-checked class on buttons
  $('.employee-buttons-row').each( function( i, buttonGroup ) {
    var $buttonGroup = $( buttonGroup );
    $buttonGroup.on( 'click', '.employee-filter-button', function( event ) {
      $buttonGroup.find('.is-checked').removeClass('is-checked');
      var $button = $( event.currentTarget );
      $button.addClass('is-checked');
    });
  });

  $('.filters-select').on( 'change', function() {
    // get filter value from option value
    var filterValue = this.value;
    // use filterFn if matches value
    filterValue = filterFns[ filterValue ] || filterValue;
    $grid.isotope({ filter: filterValue });
  });

}

export { employeeIsotopeSorter };




function sortButtonActiveState() {
  let forValidity = document.querySelectorAll(".employee-filter-button-typ2");

  if (forValidity != undefined) {
    let amountOfButton = 2;

    for (let j = 0; j < amountOfButton; j++) {
      let sortCategoryButtonsClicked = document.querySelectorAll(".employee-filter-button-typ"+j);
      let sortCategoryButtons = $(".employee-filter-button-typ"+j);

      if (sortCategoryButtonsClicked != undefined) {
        for (let i = 0; i < sortCategoryButtonsClicked.length; i++) {
          sortCategoryButtonsClicked[i].addEventListener("click", function () {
            sortCategoryButtons.removeClass("employee-filter-button-active");
            sortCategoryButtonsClicked[i].classList.add(
              "employee-filter-button-active"
            );
          });
        }
      }
    }
  }
}
export { sortButtonActiveState };



function accStateChange() {

  $('.accordion .accord').each(function(){
    $(this).click(function(){
        $(this).siblings('.panel').toggleClass('open');
    });
  });
//   $('.accordion .accord').on('click', function(){
//     $('.accordion .accord ').removeClass('open');
// });
}
export { accStateChange };
