/* General + Third-Party */
import jQuery from 'jquery';
import "owl.carousel";
import { lazyload } from './globals/lazyload';
import { inViewport } from "./globals/animation";

/* Layout-Parts */
import { header } from './layout/header';

/* Blocks */
import { heroslider } from '../../blocks/hero/script';
import { associateslider } from '../../blocks/associate/script';
import { sliderSimpleOWL } from '../../blocks/slider-simple/script';
import { slider_fancbox } from '../../blocks/slider-simple/script';
import { prodctslider } from '../../blocks/shop-the-look/script';
import { video_slider } from '../../blocks/diy-tipps/script';
import { diyvideo } from '../../blocks/diy-tipps/script';
import { videoplay } from '../../blocks/diy-tipps/script';
import { klickpoint } from '../../blocks/wohntrend-klickpoints/script';
import { video_fancbox } from '../../blocks/mediathek/script';

import { employeeIsotopeSorter } from "../../blocks/employee-sort/script";
import { sortButtonActiveState } from "../../blocks/employee-sort/script";
import { accStateChange } from "../../blocks/employee-sort/script";
import { anchorpoints } from "../../blocks/hotspot-teaser/script";

import { checkInput } from "../../blocks/form/script";
import { callFilledClass } from "../../blocks/form/script";
import { buttonchange } from "../../blocks/form/script";

import { feedbackSlider } from "../../blocks/google-feedback/script";

import { kuechensliderOWL } from "../../blocks/kuechenslider/script";

jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();
  inViewport();

  //Layout-Parts
  header();

  //Blocks
  heroslider();
  associateslider();
  sliderSimpleOWL();
  slider_fancbox();
  prodctslider();
  diyvideo();
  video_slider();
  klickpoint();
  video_fancbox();
  videoplay();
  feedbackSlider();

  employeeIsotopeSorter();
  sortButtonActiveState();
  accStateChange();
  anchorpoints();

  kuechensliderOWL();
  
  checkInput();
  callFilledClass();
  buttonchange();
});


// //Go through each carousel on the page
// $('.owl-carousel').each(function() {
//     //Find each set of dots in this carousel
//   $(this).find('.owl-dot').each(function(index) {
//     //Add one to index so it starts from 1
//     $(this).attr('aria-label', index + 1);
//   });
// });
