function header() {

  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('.main-menu').toggleClass("open");
    $('.wrap').toggleClass("open");
    $('.meta-menu').toggleClass("open");
    $('header').toggleClass("open");
    $('html, body, main').toggleClass("hidden");
  });
  //verhindert, dass nav-elemente mit unterpunkten Links öffnen
  $("li.children a.a_no_click").click(function (event) {
    console.log("test");
    event.preventDefault();
  });

  $('header .backdrop').click(function(){
    $('header').removeClass("open");
    $('.main-menu').removeClass("open");
    $('.wrap').removeClass("open");
    $('.meta-menu').removeClass("open");
    $('.navbar-toggler').removeClass("open");
    $('html, body, main').removeClass("hidden");
  });

  $('#main-menu .children').click( function(e){

    if ($(this).hasClass('show')) {
      $(this).removeClass('show')
      $('body').removeClass('overlay');
      // enableScroll();
    } else {
        //aktive menüpunkte deaktivieren
        $('#main-menu li.children').each(function( index ) {
          $(this).removeClass('show');
        });
      // $(this).parent().addClass("show");
      $(this).addClass('show');
      // $(this).siblings().removeClass('show');
      $('body').addClass('overlay');
      // disableScroll();
    }
    });
    // $('#main-menu li.children').each(function( index ) {
    //   if ($(this).hasClass('active')) {
    //     $(this).addClass('show');
    //   }
    // });

  //ADD CLASS BY SCROLLING
  function disableScroll() {
  // Get the current page scroll position
  var scrollTop = window.scrollY;
  var scrollLeft = window.scrollX;

      // if any scroll is attempted, set this to the previous value
      window.onscroll = function() {
          window.scrollTo(scrollLeft, scrollTop);
      };
  }

  function enableScroll() {
      window.onscroll = function() {};
  }

  $(window).scroll(function(){
    var st = $(window).scrollTop();
    if ( st > 0 ) {
      $('header').addClass('fixed');
    } else {
      $('header').removeClass('fixed');
    }
  });

  var st = $(window).scrollTop();
  if ( st > 0 ) {
    $('header').addClass('fixed');
  } else {
    $('header').removeClass('fixed');
  }
}
export { header };
