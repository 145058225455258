import "owl.carousel";

function video_slider() {
  let video_slider = jQuery(".video_slider");

  video_slider.owlCarousel({

    navText: [
      '<svg id="Komponente_4_1" data-name="Komponente 4 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow"><g id="Gruppe_25" data-name="Gruppe 25"><path id="Pfad_44" data-name="Pfad 44" d="M.275,137.149h0l4.9-4.875A.938.938,0,0,1,6.5,133.6l-3.289,3.273H23.063a.937.937,0,1,1,0,1.875H3.208L6.5,142.023a.938.938,0,0,1-1.323,1.329l-4.9-4.875h0A.938.938,0,0,1,.275,137.149Z" transform="translate(0 -132)"/></g></g></svg>',
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="11.625" viewBox="0 0 24 11.625"><g id="right-arrow" transform="translate(0 -132)"><g id="Gruppe_25" data-name="Gruppe 25" transform="translate(0 132)"><path id="Pfad_44" data-name="Pfad 44" d="M23.725,137.149h0l-4.9-4.875A.938.938,0,0,0,17.5,133.6l3.289,3.273H.937a.937.937,0,1,0,0,1.875H20.792L17.5,142.023a.938.938,0,0,0,1.323,1.329l4.9-4.875h0A.938.938,0,0,0,23.725,137.149Z" transform="translate(0 -132)"/></g></g></svg>',
    ],
    mouseDrag: false,
    touchDrag: true,
    loop: false,
    nav: true,
    video: true,
    dots: true,
    stagePadding: 0,
    singleItem:true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 1,
      },
      1400: {
        items: 1,
      },
    },
  });
  $(".owl-dot").attr('aria-label',"Dot");
  $(".owl-prev").attr('aria-label',"Previous").attr('role',"button");
  $(".owl-next").attr('aria-label',"Next").attr('role',"button");
}

export { video_slider };


function videoplay() {

  $('.block-diy-tipps').each(function(){

      $(this).find('.video-wrapper').each(function(){

          $(this).find('.play-button').on('click', function(){
              $(this).fadeOut();
              $(this).siblings('.play-button').fadeOut();
              console.log('450');
              setTimeout(() => {
                  $(this).siblings('video')[0].play();
              }, 450);
          });

      });

  });

} export { videoplay };


function diyvideo() {

  var min_w = 300; // minimum video width allowed
  var vid_w_orig;  // original video dimensions
  var vid_h_orig;

  jQuery(function() { // runs after DOM has loaded
      
      vid_w_orig = parseInt(jQuery('video').attr('width'));
      vid_h_orig = parseInt(jQuery('video').attr('height'));
      $('#debug').append("<p>DOM loaded</p>");
      
      jQuery(window).resize(function () { resizeToCover(); });
      jQuery(window).trigger('resize');
  });

  function resizeToCover() {
      
      // set the video viewport to the window size
      jQuery('.video-wrapper').width(jQuery(window).width());
      jQuery('.video-wrapper').height(jQuery(window).height());

      // use largest scale factor of horizontal/vertical
      var scale_h = jQuery(window).width() / vid_w_orig;
      var scale_v = jQuery(window).height() / vid_h_orig;
      var scale = scale_h > scale_v ? scale_h : scale_v;

      // don't allow scaled width < minimum video width
      if (scale * vid_w_orig < min_w) {scale = min_w / vid_w_orig;};

      // now scale the video
      jQuery('video').width(scale * vid_w_orig);
      jQuery('video').height(scale * vid_h_orig);
      // and center it by scrolling the video viewport
      jQuery('.video-wrapper').scrollLeft((jQuery('video').width() - jQuery(window).width()) / 2);
      jQuery('.video-wrapper').scrollTop((jQuery('video').height() - jQuery(window).height()) / 2);
  };
}

export { diyvideo };
