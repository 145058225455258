function anchorpoints() {

    const anchorpoints = document.getElementsByClassName("anchorpoint_svg");
    if (anchorpoints.length!=0) {
        $(".anchorpoint_svg").click(function(){
            $(".anchorpoint_svg").removeClass("show_active");
            if ($( this ).hasClass("show_active")) {
                $( this ).removeClass("show_active");
            }
            else{
                $( this ).addClass("show_active");
            }
            $(".anchorpoint_modal").removeClass("show_an"); //bisherige hotspots ausblenden
            if ($( this ).next().hasClass("show_an")) {
                $( this ).next().removeClass("show_an");
            }
            else{
                $( this ).next().addClass("show_an");
            }
            $(".anchorpoint_modal").removeClass("show_active"); //bisherige hotspots ausblenden
            if ($( this ).next().hasClass("show_active")) {
                $( this ).next().removeClass("show_active");
            }
            else{
                $( this ).next().addClass("show_active");
            }
        });
    }

} export { anchorpoints };
